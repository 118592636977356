<!--
 * @Author: Will
 * @Date: 2022-01-12 18:03:36
 * @LastEditors: Will
 * @LastEditTime: 2022-06-16 17:36:36
-->
<template>
  <div class="main">
    <div id="loading">
      <div id="loading-center">
        <div id="loading-center-absolute">
          <div class="object" id="object_one"></div>
          <div class="object" id="object_two"></div>
          <div class="object" id="object_three"></div>
          <div class="object" id="object_four"></div>
          <div class="object" id="object_five"></div>
          <div class="object" id="object_six"></div>
          <div class="object" id="object_seven"></div>
          <div class="object" id="object_eight"></div>
          <div class="object" id="object_big"></div>
        </div>
      </div>
    </div>
    <div class="txt">
      <div class="p">{{ $t('正在验证您的身份，请稍等') }}</div>
    </div>
  </div>
</template>
<script>
import { reactive, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { wxworkH5Login } from "@/api/user";
import { currentHost } from "@/utils/request.js";
import { successCallback } from "@/utils/login.js";
export default {
  setup() {
    const route = useRoute();
    const store = useStore();
    const state = reactive({
      code: route.query.auth_code || "",
      mobileDomain: route.query.mobiledomain || currentHost + "-m",
      appid: route.query.appid || "",
      redirect: decodeURIComponent(route.query.redirect || "/"),
    });

    onMounted(() => {
      fnLogin();
    });

    const fnLogin = () => {
      //通过code获取用户的基本信息
      wxworkH5Login({
        code: state.code,
        site: currentHost,
        appid: state.appid,
        redirect: state.redirect,
        url: location.href,
      }).then((res) => {
        if (res.ret == 0) {
          if (res.data.token) {
            successCallback(res, state);
          } else {
            let callbackUrl =
              location.protocol +
              "//" +
              state.mobileDomain +
              "/view/login/wxwork?appid=" +
              res.data +
              "&redirect=" +
              encodeURIComponent(state.redirect);
            window.location.href =
              "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
              res.data +
              "&redirect_uri=" +
              encodeURIComponent(callbackUrl) +
              "&response_type=code&scope=snsapi_base&state=123#wechat_redirect";
          }

          store.dispatch("setPlatform", "wxwork");
        }
      });
    };

    return {};
  },
};
</script>

<style lang="less" scoped>
@import "../../assets/style/login.less";
</style>
